import styles from './UserTable.module.scss';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React from 'react';
import { useTable } from 'react-table';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';

const UserTable = ({ columns, tableRowsData, handleTableRowClick }) => {
  const tableInstance = useTable({
    columns,
    data: tableRowsData,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;
  const renderUpperCase = item =>
    item !== null && item.charAt(0).toUpperCase() + item.slice(1);
  return (
    <>
      <table {...getTableProps()} className={styles.tableWrapper}>
        <thead className={styles.tableHead}>
          {headerGroups.map(headerGroup => {
            return (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={styles.tableHeadRow}
              >
                {headerGroup.headers.map(column => {
                  return (
                    <Content
                      as="th"
                      size="uiText50"
                      theme="neutral"
                      {...column.getHeaderProps([
                        {
                          className: column.className,
                        },
                      ])}
                      className={styles.tableHeadCell}
                    >
                      <div>
                        <div>{column.render('header')}</div>
                      </div>
                    </Content>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()} className={styles.tableBody}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={handleTableRowClick(row)}
                className={styles.tableBodyRow}
                tabIndex={0}
              >
                {row.cells.map(cell => {
                  return (
                    <Content
                      size="uiText50"
                      as="td"
                      theme="neutral"
                      {...cell.getCellProps([
                        {
                          className: `${cell.column.className} ${styles.tableBodyCell}`,
                        },
                      ])}
                    >
                      {cell.column.id === 'role'
                        ? renderUpperCase(cell.value.join())
                        : cell.render('Cell')}
                    </Content>
                  );
                })}
                <td className={styles.actionColumn}>
                  <IconWrapper
                    Icon={IconComponents.ArrowForwardIcon}
                    alt="arrowRight"
                    className={styles.icon}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default UserTable;
