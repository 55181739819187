import styles from './AdminUsersList.module.scss';
import Layout from 'components/Layout/Layout';
import Container from 'components/Container/Container';
import Client from 'api/client';
import UserTable from 'components/UserTable/UserTable';
import { AuthorizationContextProvider } from 'contextProvider';
import PrivateElement from 'pages/admin/components/PrivateElement.js';
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Button from '@moonshineragency/ui/src/components/Button/Button';

const AdminUsersList = ({
  layoutStructure = {
    showBreadCrumbs: false,
    showTitle: false,
  },
}) => {
  const [tableRowsData, setTableRowsData] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const columns = [
    {
      header: 'ID',
      className: styles.userData,
      accessor: 'id',
    },
    {
      header: 'First name',
      className: styles.userData,
      accessor: 'first_name',
    },
    {
      header: 'Last name',
      className: styles.userData,
      accessor: 'last_name',
    },
    {
      header: 'Email address',
      className: styles.userData,
      accessor: 'email',
    },
    {
      header: 'Role',
      className: styles.userData,
      accessor: 'role',
    },
  ];
  useEffect(() => {
    const fetchUser = async () => {
      const { data: userData } = await Client.usersList.get();
      setTableRowsData(userData);
    };
    fetchUser();
  }, []);
  useEffect(() => {
    const fetchUserRoles = async () => {
      const { data: userRolesData } = await Client.userRoles.get();
      setUserRoles(userRolesData);
    };
    fetchUserRoles();
  }, []);

  useEffect(() => {
    const fetchUserRoles = async () => {
      const { data: userRolesData } = await Client.userRoles.get();
      setUserRoles(userRolesData);
    };
    fetchUserRoles();
  }, []);

  const handleTableRowClick = row => () => {
    const userData = tableRowsData.find(user => user.id === row.original.id);
    navigate(`/admin/users/list/${row.original.id}`, {
      state: { userData, userRoles },
    });
  };
  const handleCreateUserClick = () =>
    navigate(`/admin/users/list/createUser`, {
      state: { userRoles },
    });

  return (
    <Layout title="Users" isAdmin layoutStructure={layoutStructure}>
      <AuthorizationContextProvider>
        <PrivateElement scope="manage users">
          <Container size="wide">
            <div className={styles.headerWrapper}>
              <Heading size="h2" theme="primary" noSpacing>
                Users
              </Heading>
              <Button
                theme="secondary"
                target="_blank"
                size="small"
                onClick={handleCreateUserClick}
                className={styles.createButton}
              >
                Create new user
              </Button>
            </div>

            <UserTable
              columns={columns}
              tableRowsData={tableRowsData}
              handleTableRowClick={handleTableRowClick}
            />
          </Container>
        </PrivateElement>
      </AuthorizationContextProvider>
    </Layout>
  );
};

export default AdminUsersList;
